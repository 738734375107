<template>
  <section class="checkorder-wrapper">
    <card title="会员信息">
      <section>
        <el-button
          class="card-btn"
          size="small"
          type="primary"
          @click="$router.back(-1)"
          >返回</el-button
        >
        <vipInfo :tableData="vipData"> </vipInfo>
      </section>
    </card>
    <card title="订单详情">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="editOrder"
        >{{ editFlag ? "完成" : "编辑" }}</el-button
      >
      <el-table
        :show-header="false"
        :data="orderData"
        border
        :span-method="objectSpanMethod"
        style="margin-top: 20px"
      >
        <el-table-column width="180" prop="label"></el-table-column>
        <el-table-column prop="valueLeft" width="200">
          <template slot-scope="scope">
            <!-- <el-select
              v-model="scope.row.valueLeft"
              v-show="scope.$index == 4 && editFlag"
            >
              <el-option
                v-for="(item, index) in orderStatus"
                :key="index"
                :value="item"
                :label="item"
              ></el-option>
            </el-select> -->
            <el-input
              placeholder="请输入内容"
              v-show="scope.$index == 5 && editFlag"
              v-model="scope.row.valueLeft"
              size="medium"
            ></el-input>
            <el-upload
              v-if="scope.$index == 6"
              :action="imgUploadUrl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccesspic"
              :file-list="picFile"
              list-type="picture-card"
              ref="upload"
              :limit="1"
              :class="{ disabled: uploadDisabled }"
              :on-change="onChange"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span
              v-show="
                scope.$index < 5 ||
                scope.$index > 6 ||
                (!editFlag && scope.$index !== 6)
              "
              style="line-height: 36px"
              >{{ scope.row.valueLeft }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="valueRight"></el-table-column>
      </el-table>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
import vipInfo from "../../components/vipInfo";
export default {
  components: {
    card,
    vipInfo
  },
  data() {
    return {
      imgUploadUrl: "https://www.ymxjkx.com/api/back/product/uploadImage",
      editFlag: false,
      imgPreviewUrl: "",
      picFile: [],
      vipData: [
        { key: "手机号", value: "" },
        { key: "姓名", value: "" },
        { key: "床位", value: "" },
        { key: "宝宝生日", value: "" },
        { key: "宝宝体重", value: "" },
        { key: "地区", value: "" },
        { key: "默认地址", value: "" }
      ],
      userInfo: {},
      orderInfo: {},
      uid: 0,
      oid: 0,
      orderStatus: ["已完成", "待收货", "待发货"],
      orderData: [
        {
          label: "订单号",
          valueLeft: ""
        },
        {
          label: "购买商品",
          valueLeft: ""
        },
        {
          label: "物流公司",
          valueLeft: ""
        },
        {
          label: "金额",
          valueLeft: ""
        },
        {
          label: "订单状态",
          valueLeft: ""
        },

        {
          label: "运单号",
          valueLeft: ""
        },
        {
          label: "电子发票",
          valueLeft: ""
        }
      ],
      orderMap: [
        "oid",
        "opLinks",
        "type",
        "money",
        "status",
        "waybill",
        "invoice"
      ],
      uploadDisabled: false
    };
  },
  methods: {
    // 合并列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex <= 6) {
        if (columnIndex === 1) {
          return [1, 2];
        } else if (columnIndex === 2) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          if (rowIndex % (this.orderData.length - 7) === 0) {
            return {
              rowspan: this.orderData.length - 7,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    },
    editOrder() {
      if (this.editFlag) {
        for (let i = 4; i < 7; i++) {
          this.orderInfo[this.orderMap[i]] = this.orderData[i].valueLeft;
        }
        console.log(this.orderInfo);
        this.$axios({
          url: "/back/order/order/updateOrder",
          method: "post",
          transformRequest: [
            function(data) {
              return JSON.stringify(data);
            }
          ],
          data: this.orderInfo,
          headers: { "Content-Type": "application/json" }
        }).then(res => {
          if (res.data.code == 200) {
            this.getOrderInfo();
            this.$message({
              type: "success",
              message: "修改订单成功"
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
        });
      }
      this.editFlag = !this.editFlag;
    },
    handleRemove(file, fileList) {
      this.$axios({
        url: "/back/product/deleteImage",
        params: {
          fileUrl: file.url
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.orderData[6].valueLeft = "";
          this.picFile = [];
          this.uploadDisabled = fileList.length ? true : false;
          this.$message({
            type: "success",
            message: "移除成功"
          });
        } else {
          this.$message({
            type: "error",
            message: "移除失败"
          });
        }
      });
    },
    onChange(file, fileList) {
      this.uploadDisabled = fileList.length ? true : false;
    },
    handlePreview(file) {
      this.imgPreviewUrl = file.url;
      this.imgPreview = true;
    },
    handleSuccesspic(res, file, fileList) {
      this.orderData[6].valueLeft = res.data;
      this.picFile[0] = res.data;
      console.log(this.picFile);
    },

    getUserInfo() {
      this.$axios({
        url: "/back/user/getUser",
        params: {
          uId: this.uid
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.vipDataFormmate(res.data.data);
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
      });
    },
    vipDataFormmate(data) {
      const keyMap = ["tel", "uname", "bed", "birthday", "weight", "address"];
      this.vipData.forEach((item, index, arr) => {
        if (index < 5) {
          if (index === 3) {
            const bir = new Date(data[keyMap[index]]);
            arr[index].value =
              bir.getFullYear() +
              "年" +
              (bir.getMonth() + 1) +
              "月" +
              bir.getDate() +
              "日";
          } else arr[index].value = data[keyMap[index]];
        } else {
          let area = data[keyMap[5]].province + data[keyMap[5]].city;
          if (!area) {
            area = "";
          }
          arr[index].value = index === 5 ? area : data[keyMap[5]].address;
        }
      });
    },
    getOrderInfo() {
      this.$axios({
        url: "/back/order/order/getOrder",
        params: {
          oId: this.oid
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.orderInfo = res.data.data;
          this.orderData = this.$options.data().orderData;
          for (let i = 0; i < this.orderMap.length; i++) {
            if (i !== 1) {
              this.orderData[i].valueLeft = this.orderInfo[this.orderMap[i]];
            } else {
              for (let item of this.orderInfo.opLinks) {
                const tempObj = {
                  label: "评价",
                  valueLeft: item.pName,
                  valueRight: item.comment ? item.comment : "无"
                };
                this.orderData[1].valueLeft += item.pName + "\n";
                this.orderData.push(tempObj);
              }
            }
          }
          this.picFile = this.orderData[6].valueLeft
            ? [{ name: "0", url: "https://" + this.orderData[6].valueLeft }]
            : [];
          this.uploadDisabled = this.picFile.length;
        } else {
          this.$message({
            type: "error",
            message: "订单信息获取失败"
          });
        }
      });
    }
  },
  created() {
    this.$openLoading();
    this.uid = this.$route.query.uid;
    this.oid = this.$route.query.oid;
    Promise.all([this.getOrderInfo(), this.getUserInfo()]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style lang="less">
.disabled .el-upload--picture-card {
  display: none;
}

.el-table .cell {
  white-space: pre-line;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
}
</style>
