<template>
  <table class="vipInfoTable">
    <tr v-for="index in rowCount" :key="index">
      <td class="column">{{ tableData[index * 2 - 2].key }}</td>
      <td>{{ tableData[index * 2 - 2].value }}</td>
      <td class="column">
        {{
          tableData[index * 2 - 1] !== undefined
            ? tableData[index * 2 - 1].key
            : ""
        }}
      </td>
      <td>
        {{
          tableData[index * 2 - 1] !== undefined
            ? tableData[index * 2 - 1].value
            : ""
        }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["tableData"],
  computed: {
    rowCount: function() {
      return Math.ceil(this.tableData.length / 2);
    }
  }
};
</script>

<style lang="less">
.vipInfoTable {
  font-size: 14px;
  color: #606266;
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  tr {
    td {
      border: 1px solid #e6eaee;
      width: 150px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .column {
      color: #393c3e;
      width: 17%;
    }
  }
}
</style>
